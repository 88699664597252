<script setup>
import UserActions from "@/components/UserActions.vue";
import PaginatedTableOptions from "@/components/PaginatedTableOptions.vue";

defineProps({
  tableId: {
    type: String,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    required: true,
  },
  entries: {
    type: Array,
    required: true,
  },
  footers: {
    type: Array,
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  canDelete: {
    type: Boolean,
    required: true,
  },
  externalDeleteFunc: {
    type: Function,
    required: false,
    default: () =>
      Promise.resolve({
        status: true,
        message: "Successful entry deletion",
        data: {},
      }),
  },
  deletePromptParams: {
    type: Array,
    required: false,
    default: () => [],
  },
  externalEditFunc: {
    type: Function,
    required: false,
    default: () =>
      Promise.resolve({
        status: true,
        message: "Successful entry modification",
        data: {},
      }),
  },
});
</script>

<template>
  <paginated-table-options
    v-if="total"
    v-model="numPerPage"
    :all-num-per-page="allNumPerPage"
    :is-at-end="isAtEnd"
    :is-at-start="isAtStart"
    :options-from="optionsFrom"
    :options-of="optionsOf"
    :options-to="optionsTo"
    :to-first-page="toFirstPage"
    :to-last-page="toLastPage"
    :to-next-page="toNextPage"
    :to-prev-page="toPrevPage"
  ></paginated-table-options>
  <div v-if="total" class="table-holder">
    <table :id="tableId">
      <thead>
        <tr>
          <th>#</th>
          <th v-for="header in headers" :key="header">{{ header }}</th>
          <th v-if="canEdit || canDelete">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(entry, index) in pagedData" :key="index">
          <td>{{ calcRowIndex(index + 1) }}</td>
          <td v-for="col in columns" :key="col">{{ entry[col] }}</td>
          <td v-if="canEdit || canDelete">
            <UserActions
              :can-delete="canDelete"
              :can-edit="canEdit"
              @delete="internalDelete(entry)"
              @edit="internalEdit(entry)"
            ></UserActions>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="shouldShowFooter">
        <tr>
          <td></td>
          <td v-for="col in footers" :key="col">{{ col }}</td>
          <td v-if="canEdit || canDelete"></td>
        </tr>
      </tfoot>
    </table>
  </div>
  <paginated-table-options
    v-if="total"
    v-model="numPerPage"
    :all-num-per-page="allNumPerPage"
    :is-at-end="isAtEnd"
    :is-at-start="isAtStart"
    :options-from="optionsFrom"
    :options-of="optionsOf"
    :options-to="optionsTo"
    :to-first-page="toFirstPage"
    :to-last-page="toLastPage"
    :to-next-page="toNextPage"
    :to-prev-page="toPrevPage"
  ></paginated-table-options>
</template>
<script>
import { notifyError, notifySuccess } from "@/services/NotificationService";
import { watch } from "vue";

export default {
  name: "PaginatedTable",
  data() {
    return {
      shouldShowFooter: this.showFooter(this.footers),
      currentPage: 0,
      numPerPage: 10,
    };
  },
  computed: {
    total: function () {
      return this.entries.length;
    },
    optionsOf: function () {
      return this.total;
    },
    optionsFrom: function () {
      return this.currentPage * this.numPerPage + 1;
    },
    optionsTo: function () {
      return Math.min(this.optionsFrom - 1 + this.numPerPage, this.total);
    },
    pagedData: function () {
      const startAt = this.currentPage * this.numPerPage;
      const endBefore = startAt + this.numPerPage;
      if (this.numPerPage === 0) {
        return this.entries;
      } else {
        return this.entries.slice(startAt, endBefore);
      }
    },
    numPages: function () {
      return Math.ceil(this.total / this.numPerPage);
    },
    allNumPerPage: function () {
      const dataLength = this.total;
      const list = [];
      if (dataLength > 10) list.push(10);
      if (dataLength > 50) list.push(50);
      if (dataLength > 100) list.push(100);
      if (dataLength > 500) list.push(500);
      if (dataLength > 1000) list.push(1000);
      return list.concat(Math.ceil((dataLength + 1) / 10) * 10);
    },
    isAtStart: function () {
      return this.optionsFrom === 1;
    },
    isAtEnd: function () {
      return this.optionsTo === this.total;
    },
  },
  mounted() {
    watch(
      () => this.numPerPage,
      () => {
        this.currentPage = 0;
      }
    );
    watch(
      () => this.total,
      () => {
        if (this.entries.length === 0) {
          this.currentPage = 0;
        }
      }
    );
  },
  methods: {
    internalDelete(entry) {
      const OPERATION = "Deletion operation";

      const reifiedParams = this.deletePromptParams
        .map((param) => entry[param])
        .join(" - ");
      const prompt = `Delete [${reifiedParams}]?`;
      if (confirm(prompt)) {
        this.externalDeleteFunc(entry.id)
          .then((data) => {
            if (data.status) {
              notifySuccess(OPERATION, data.message);
              this.removeEntry(entry.id);
            } else {
              notifyError(OPERATION, data.message);
            }
          })
          .catch((error) => notifyError(OPERATION, error));
      }
    },
    internalEdit(entry) {
      this.externalEditFunc(entry);
    },
    removeEntry(id) {
      const foundAt = this.entries.findIndex((x) => x.id === id);
      if (foundAt !== -1) {
        this.entries.splice(foundAt, 1);
      }
    },
    toFirstPage() {
      this.currentPage = 0;
    },
    toLastPage() {
      this.currentPage = Math.max(this.numPages - 1, 0);
    },
    toPrevPage() {
      this.currentPage = Math.max(this.currentPage - 1, 0);
    },
    toNextPage() {
      if (this.currentPage === this.numPages - 1) return;
      this.currentPage = this.currentPage + 1;
    },
    showFooter(footerCells) {
      for (const cell of footerCells) {
        if (cell && cell !== "") {
          return true;
        }
      }
      return false;
    },
    calcRowIndex(index) {
      return this.currentPage * this.numPerPage + index;
    },
  },
};
</script>
